<template>
    <div class="order-details-modal">
        <div class="order-details-modal-wrapper">
            <div class="order-details-modal-wrapper-title">
                <h2>הזמנה מס - {{order.order_num}}</h2>
                <div @click="$emit('close')" class="exit-btn"><i class="material-icons icon delete-icon" >close</i></div>
            </div>
            <div class="order-details-modal-wrapper-content">
                <div class="order-details-modal-wrapper-content-details">
                    <div class="detail">
                        <h3 style="text-decoration: underline; font-weight: 500;">שם הסניף</h3>
                        <h4>{{order.branche}}</h4>
                    </div>
                    <div class="detail">
                        <h3 style="text-decoration: underline; font-weight: 500;">שם הספק</h3>
                        <h4>{{order.supplier_name}}- ({{order.supplier_num}})</h4>
                    </div>
                    <div class="detail">
                        <h3 style="text-decoration: underline; font-weight: 500;">סטטוס הזמנה</h3>
                        <h4>{{order.status}}</h4>
                    </div>
                </div>
                <div class="order-details-modal-wrapper-content-gallery">
                    גלריית תמונות של המוצרים  - בהמשך
                </div>
            </div>
            <div class="order-details-modal-wrapper-footer">
                <el-select
                    v-model="order.status"
                    class="m-2"
                    placeholder="שינוי סטטוס"
                    size="large"
                    clearable
                >
                    <el-option
                    v-for="status in statuses_options"
                    :key="status"
                    :value="status"
          
                    />
                </el-select>
                <el-button @click="$emit('update_order_status')" style="margin: 0 5px;" type="success">עדכון סטטוס</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    emits:['close','update_order_status'],
    props:['order'],
    setup () {
        


        const statuses_options = ref([
            'חדש',
            'סגור'
        ])

        return {
            statuses_options,
        }
    }
}
</script>

<style scoped>
    .order-details-modal{
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        padding: 0 5px;
        z-index: 1000;
    }
    .order-details-modal-wrapper{
        width: 100%;
        max-width: 500px;
        margin: 10px auto;
        background: #fff;
        border-radius: 10px;
        color: #333;
        height: 400px;
        display: flex;
        flex-direction: column;
    }
    .order-details-modal-wrapper-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .order-details-modal-wrapper-content{
        flex: 1;
        overflow-y: auto;
        padding: 10px;
    }
    .order-details-modal-wrapper-content-details{
        display: flex;
        width: 100%;
        gap: 5px;
    }
    .order-details-modal-wrapper-content-details > .detail{
        text-align: center;
        flex: 1;
    }
    .order-details-modal-wrapper-content-gallery{
        width: 100%;
        height: 300px;
        background: pink;
    }
    .order-details-modal-wrapper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        border-radius: 50%;
        color: var(--danger);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
</style>